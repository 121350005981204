<template>
    <Guest>
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <h1 class="pb-4 text-center text-lg">
                    <i18n-t keypath="views.NotFound.page_not_found">
                        <template v-slot:page>
                          <b>{{ current_route }}</b>
                        </template>
                    </i18n-t>
                </h1>

                <router-link :to="{ name: dest} ">
                    <button class="button bg-gradient-to-r from-gradient-orange to-gradient-red w-full p-4 rounded text-white font-medium">
                        {{ t('back_to', {dest}) }}
                    </button>
                </router-link>
            </div>
        </div>
    </Guest>
</template>

<script>
import Guest from "@/wrappers/Guest";
import { isLoggedIn } from "@/helpers/storage/AuthHelper";
import useI18n from "@/modules/globalI18n";

export default {
    name: "NotFound",
    setup() {
        return useI18n({
            viewPrefix: "NotFound"
        });
    },
    components: {
        Guest,
    },
    computed: {
        dest: () => {
            if(isLoggedIn()){
                return 'dashboard';
            } else {
                return 'login';
            }
        },
        current_route: function(){
            return this.$route.fullPath
        }
    }
}
</script>